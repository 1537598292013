import { styled } from '@linaria/react';

import { MEDIA } from '@singledesk/theme';
import { BODY } from '@singledesk/typography';
import { SimpleLink as _SimpleLink } from '@singledesk/button';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;

  text-align: center;

  @media (min-width: ${MEDIA.tablet}) {
    padding: 80px 30px;
  }

  @media (min-width: ${MEDIA.tablet}) {
    padding: 50px 130px;
  }
`;

export const Description = styled.p`
  margin-top: 40px;

  ${BODY.SMALL_REGULAR};
`;

export const Text = styled.span`
  opacity: 0.4;
`;

export const SimpleLink = styled(_SimpleLink)`
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }
`;
