import { styled } from '@linaria/react';

import { MEDIA } from '@singledesk/theme';

export const Wrapper = styled.div`
  width: 100%;
  display: grid;

  text-align: center;

  @media (min-width: ${MEDIA.tablet}) {
    grid-template-columns: 1.2fr 1fr;
    gap: 32px 60px;

    text-align: left;

    &[data-reverse] {
      grid-template-columns: 1fr 1.2fr;
    }
  }
`;

export const Title = styled.h2`
  text-align: center;

  @media (min-width: ${MEDIA.tablet}) {
    margin-bottom: 32px;

    text-align: left;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
`;

export const SmallIconWrapper = styled.div`
  width: 200px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin: 0 auto;

  @media (min-width: ${MEDIA.tablet}) {
    display: none;
  }

  svg {
    width: 100%;
    height: auto;
  }
`;

export const IconWrapper = styled.div`
  display: none;
  flex-shrink: 0;
  margin: 0 auto;

  @media (min-width: ${MEDIA.tablet}) {
    display: flex;
    width: 260px;
    grid-column: 2;
    grid-row: 1;
  }

  @media (min-width: ${MEDIA.desktop}) {
    width: 420px;
  }

  svg {
    width: 100%;
    height: auto;
  }

  &[data-reverse] {
    grid-column: 1;

    @media (min-width: ${MEDIA.tablet}) {
      margin: 0 0 0 auto;
    }
  }
`;
