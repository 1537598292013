import React from 'react';

import { SectionWrapper } from 'components/Layout';
import GetAccessButton from 'components/GetAccessButton';

import { Wrapper, Description, SimpleLink, Text } from './styled';

const GetAccess = () => (
  <SectionWrapper>
    <Wrapper>
      <GetAccessButton />

      <Description>
        <Text>
          Если вы ищете инструмент повышения производительности для своих
          офисных, удаленных или распределенных сотрудников, попробуйте нашу
          платформу! Или напишите нам по адресу{' '}
        </Text>

        <SimpleLink href='mailto:info@singledesk.ru'>
          info@singledesk.ru
        </SimpleLink>
      </Description>
    </Wrapper>
  </SectionWrapper>
);

export default GetAccess;
