import { styled } from '@linaria/react';
import { css } from '@linaria/core';

import { MEDIA } from '@singledesk/theme';

import { SectionWrapper as _SectionWrapper } from 'components/Layout';

export const SectionWrapper = styled(_SectionWrapper)`
  @media (min-width: ${MEDIA.tablet}) {
    padding-top: 0 !important;
  }
`;

export const Wrapper = styled.div`
  padding-top: 50px;
`;

export const TabsWrapper = styled.div`
  padding: 0 24px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  width: 100%;
  margin-top: 16px;

  @media (min-width: ${MEDIA.tablet}) {
    flex-direction: row;
    align-items: center;
    min-height: 625px;
    margin-top: 40px;
  }
`;

export const Image = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  object-fit: contain;
  visibility: hidden;

  &[data-show] {
    visibility: visible;
  }
`;

export const sectionAnimation = css`
  @media (min-width: ${MEDIA.tablet}) {
    &-enter {
      opacity: 0;

      &.next {
        transform: translate3d(40px, 0, 0);
      }

      &.prev {
        transform: translate3d(-40px, 0, 0);
      }
    }

    &-enter-active {
      opacity: 1 !important;
      transform: translate3d(0, 0, 0) !important;
      transition: all 0.7s;
    }

    &-exit {
      visibility: hidden;

      ${Image} {
        visibility: hidden;
      }
    }

    &-exit-active {
      position: absolute;
      visibility: hidden;

      ${Image} {
        visibility: hidden;
      }
    }
  }

  @media (max-width: ${MEDIA.tablet}) {
    &-enter {
      opacity: 0;
    }

    &-enter-active {
      opacity: 1 !important;
      transition: 0.7s;
    }

    &-exit {
      visibility: hidden;

      ${Image} {
        visibility: hidden;
      }
    }

    &-exit-active {
      position: absolute;
      visibility: hidden;

      ${Image} {
        visibility: hidden;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;

  text-align: center;

  @media (min-width: ${MEDIA.tablet}) {
    max-width: calc(100% - 274px);
    align-items: flex-start;

    text-align: left;
  }

  @media (min-width: ${MEDIA.desktop}) {
    max-width: 620px;
    width: 100%;
  }
`;

export const Items = styled.div`
  display: grid;
  gap: 25px;
  margin-top: 32px;
  padding-right: 60px;

  text-align: left;

  @media (min-width: ${MEDIA.tablet}) {
    margin-top: 44px;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: 12px;

  @media (min-width: ${MEDIA.tablet}) {
    width: 74px;
    margin-right: 32px;
  }

  svg {
    width: 100%;
    height: auto;
  }
`;

export const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemTitle = styled.h4`
  margin-bottom: 4px;
`;

export const ImageWrapper = styled.div`
  position: relative;

  width: 274px;
  height: 555.5px;
  margin: 30px auto 0;

  @media (min-width: ${MEDIA.tablet}) {
    right: -100px;

    margin: 0;
  }

  @media (min-width: ${MEDIA.desktop}) {
    right: auto;

    margin: 0 0 0 24px;
  }

  &:before {
    content: '';
    position: absolute;
    left: -150px;
    top: -140px;
    z-index: 0;

    display: none;
    width: 660px;
    height: 820px;

    background: url('./assets/phoneShadow.png') no-repeat;

    @media (min-width: ${MEDIA.tablet}) {
      display: block;
    }
  }
`;
