import * as React from 'react';

import Home from 'screens/Home';

import { GlobalLayout } from 'components/Layout';

export default function Index() {
  return (
    <GlobalLayout topForShowHeader={200}>
      <Home />
    </GlobalLayout>
  );
}
