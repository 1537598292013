import * as React from 'react';

import { Devices, Offline, PlatformCog, Sync } from 'theme/icons/other';

import RowItemsSection from 'components/RowItemsSection';

const Comfort = () => (
  <RowItemsSection
    title='Single Desk – это удобно!'
    items={[
      {
        title: 'Кроссплатформенность',
        icon: PlatformCog,
        description:
          'Сервер может быть развернут на различных платформах, в том числе, на российских операционных системах, например, Astra Linux.',
      },
      {
        title: 'Мобильность',
        icon: Devices,
        description:
          'Клиентское приложение работает на всех популярных системах – Android, iOS, Windows, MacOS, Linux.',
      },
      {
        title: 'Offline режим',
        icon: Offline,
        description:
          'Можно работать в приложении даже без связи с интернетом. Когда подключение восстановится, приложение аккуратно синхронизирует все данные.',
      },
      {
        title: 'Синхронизация',
        icon: Sync,
        description:
          'При работе пользователя одновременно на нескольких устройствах ничего не потеряется, все корректно сохранится в реальном времени.',
      },
    ]}
  />
);

export default Comfort;
