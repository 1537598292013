import { styled } from '@linaria/react';

import { MEDIA } from '@singledesk/theme';
import { BODY } from '@singledesk/typography';

export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  gap: 50px;

  @media (min-width: ${MEDIA.tablet}) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const Title = styled.h2`
  text-align: center;

  @media (min-width: ${MEDIA.tablet}) {
    margin-top: 40px;

    text-align: left;
  }
`;

export const Items = styled.div`
  width: 100%;
  display: grid;
  gap: 20px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  width: 80px;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  @media (min-width: ${MEDIA.tablet}) {
    width: 120px;
  }

  @media (min-width: ${MEDIA.desktop}) {
    width: 200px;
  }

  svg {
    width: 100%;
    height: auto;
  }
`;

export const ItemTitle = styled.p`
  ${BODY.BASE_BOLD};

  @media (min-width: ${MEDIA.desktop}) {
    width: 200px;
  }
`;
