import { styled } from '@linaria/react';

import { MEDIA } from '@singledesk/theme';
import { BODY } from '@singledesk/typography';

import { TEXT } from 'theme/vars';

import { SectionWrapper as _SectionWrapper } from 'components/Layout';

export const SectionWrapper = styled(_SectionWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-height: 890px) and (min-width: ${MEDIA.tablet}) {
    height: 100vh;
    max-height: 1100px;
    padding-top: 130px;
  }
`;

export const Wrapper = styled.div`
  position: relative;

  display: flex;
  justify-content: flex-start;
`;

export const LogoWrapper = styled.div`
  margin-bottom: 25px;

  svg {
    width: 72px;
    height: 72px;
    flex-shrink: 0;
  }

  @media (min-width: ${MEDIA.tablet}) {
    margin-bottom: 88px;

    svg {
      width: 44px;
      height: 44px;
    }
  }
`;

export const Controls = styled.div`
  width: 100%;
  max-width: 360px;
  display: grid;
  gap: 28px;

  button {
    width: 100%;
    max-width: none !important;
  }
`;

export const Links = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
`;

export const Subtitle = styled.p`
  margin: 32px 0 64px;

  ${TEXT.HERO_4};

  @media (min-width: ${MEDIA.tablet}) {
    margin-bottom: 88px;

    ${BODY.BASE_SEMIBOLD};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  position: relative;
  z-index: 2;

  text-align: center;

  @media (min-width: ${MEDIA.tablet}) {
    width: 380px;
    align-items: flex-start;
    padding-top: 70px;

    text-align: left;
  }
`;

export const ImageWrapper = styled.div`
  display: none;

  @media (min-width: ${MEDIA.tablet}) {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    margin-left: 20px;
    height: 640px;
  }
`;

export const Image = styled.div`
  @media (min-width: ${MEDIA.tablet}) {
    position: absolute;
    top: 43%;
    left: 50%;
    z-index: -1;

    width: 1072px;

    object-fit: contain;
    transform: translate(-50%, -30%);
  }

  @media (min-width: ${MEDIA.desktop}) {
    width: 1287px;
  }
`;

export const MouseWrapper = styled.a`
  display: none;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  @media (min-width: ${MEDIA.tablet}) {
    display: flex;
  }

  svg {
    #mouse__arrow-1 {
      animation: mouseAnimation 0.8s infinite alternate ease;
    }
    #mouse__arrow-2 {
      animation: mouseAnimation 0.8s infinite alternate ease;
      animation-delay: 0.4s;
    }
  }

  @keyframes mouseAnimation {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
