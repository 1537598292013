import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { StaticImage } from 'gatsby-plugin-image';

import {
  Edit,
  Lightning,
  Sync,
  Calendar,
  CheckList,
  Cog,
  Indicator,
  PieDiagram,
  Scheme,
  ShieldList,
  Speaker,
  UserCheck,
  Users1,
  Users2,
} from 'theme/icons/74';

import { Tabs } from './components';
import {
  Wrapper,
  ContentWrapper,
  Items,
  Item,
  IconWrapper,
  ItemTitle,
  ItemInfo,
  Image,
  Content,
  ImageWrapper,
  sectionAnimation,
  SectionWrapper,
  TabsWrapper,
} from './styled';

const TABS = [
  { id: 'contacts', name: 'Контакты' },
  { id: 'communication', name: 'Коммуникации' },
  { id: 'calendar', name: 'Календарь' },
  { id: 'tasks', name: 'Задачи' },
  { id: 'files', name: 'Файлы и документооборот' },
];

interface SectionItem {
  title: string;
  description: string;
  icon: React.JSXElementConstructor<React.SVGProps<SVGElement>>;
}

interface Section {
  key: string;
  title: string;
  items: SectionItem[];
}

const SECTIONS_BY_TAB: Record<string, Section> = {
  communication: {
    key: 'communication',
    title: 'Взаимодействие, каким должно быть',
    items: [
      {
        title: 'Держите высокий темп',
        description:
          'Обменивайтесь текстовыми и аудио сообщениями, отправляйте и просматривайте файлы, оперативно отвечайте и используйте эмодзи для реакции',
        icon: Lightning,
      },
      {
        title: 'Работайте с контентом',
        description:
          'Делитесь документами, просматривайте таблицы, фото и видео, прослушивайте аудио прямо в приложении',
        icon: Edit,
      },
    ],
  },
  calendar: {
    key: 'calendar',
    title: 'Создайте расписание удобное всем',
    items: [
      {
        title: 'Организуйте рабочее расписание на любом устройстве',
        description:
          'Где бы вы ни были, возьмите с собой свой рабочий календарь – события автоматически синхронизируются на всех ваших устройствах – пусть все будет под контролем',
        icon: Sync,
      },
      {
        title: 'Наполните события смыслом',
        description:
          'Добавляйте к событиям участников, файлы и медиа, пишите сообщения, настраивайте уведомления для себя и коллег',
        icon: CheckList,
      },
      {
        title:
          'Выполняйте работу в срок и без стрессов – достигайте большего каждый день',
        description:
          'Планируйте свои события и деятельность вашей команды, легко переключайтесь между календарем и задачами',
        icon: Calendar,
      },
    ],
  },
  tasks: {
    key: 'tasks',
    title: 'Определите задачи – объедините сотрудников',
    items: [
      {
        title: 'Упрощайте управление задачами',
        description:
          'Разбивайте задачи на простые шаги, устанавливайте сроки и настраивайте напоминания, чтобы следить за реализацией поставленных целей',
        icon: PieDiagram,
      },
      {
        title: 'Взаимодействуйте эффективнее и работайте сообща',
        description:
          'Сделайте работу сотрудников точной и понятной – за мгновения добавляйте задачи, устанавливайте сроки выполнения, назначайте исполнителей',
        icon: Indicator,
      },
      {
        title: 'Задействуйте полный набор инструментов для вашей работы',
        description:
          'Планируйте, организуйте и сотрудничайте внутри любого проекта с помощью гибкого управления задачами, используйте канбан-доски, настраиваемые уведомления и многое другое',
        icon: Cog,
      },
    ],
  },
  files: {
    key: 'files',
    title: 'Все документы в структурированном пространстве',
    items: [
      {
        title: 'Сотрудничайте эффективнее',
        description:
          'Используйте внутренний защищенный документооборот, делитесь файлами с коллегами и деловыми партнерами за пределами компании',
        icon: ShieldList,
      },
      {
        title: 'Взаимодействуйте просто и цените свое время',
        description:
          'Забудьте о потерянных файлах, бесконечной пересылке документов по email и долгих согласованиях – делитесь файлами, там же обсуждайте детали и держите всех в курсе дел, уведомления об изменениях в документах легко настраиваются и приходят автоматически',
        icon: Speaker,
      },
      {
        title: 'Не просто хранилище, а ваша рабочая среда',
        description:
          'Хранилище настраивается под организационную структуру вашей компании – создавайте отделы и устанавливайте права доступа',
        icon: Scheme,
      },
    ],
  },
  contacts: {
    key: 'contacts',
    title: 'Простая организация делового общения',
    items: [
      {
        title: 'Получайте полную информацию о сотрудниках в едином приложении',
        description:
          'Все в одном месте – контакты, должность, отдел, текущие проекты, рабочее время, локации,статусы и многое другое',
        icon: UserCheck,
      },
      {
        title: 'Работайте в привычном окружении',
        description:
          'Оцените удобство, продуманность и организацию адресной книги – все настраивается в соответствии со структурой вашего бизнеса: иерархия, должности, отделы, проекты и др.',
        icon: Users1,
      },
      {
        title: 'Сформируйте цифровой офис',
        description:
          'Организуйте, находите и фильтруйте контакты, создавайте группы и каналы, добавляйте контакты в избранное, автоматически получайте информацию обо всех кадровых изменениях',
        icon: Users2,
      },
    ],
  },
};

const Features = () => {
  const [activeTabId, setActiveTabId] = React.useState('contacts');
  const [animationDirection, setAnimationDirection] = React.useState<
    'next' | 'prev'
  >('next');

  const section = SECTIONS_BY_TAB[activeTabId];

  const onChangeTab = (newTabId: string) => {
    if (newTabId !== activeTabId) {
      const tabIds = TABS.map(item => item.id);

      const newAnimationDirection =
        tabIds.indexOf(newTabId) > tabIds.indexOf(activeTabId)
          ? 'next'
          : 'prev';

      setAnimationDirection(newAnimationDirection);
      setActiveTabId(newTabId);
    }
  };

  return (
    <Wrapper id='features-section'>
      <TabsWrapper>
        <Tabs tabs={TABS} activeTabId={activeTabId} onChange={onChangeTab} />
      </TabsWrapper>

      <SectionWrapper>
        {section && (
          <TransitionGroup>
            <CSSTransition
              key={section.key}
              timeout={700}
              classNames={sectionAnimation}
              unmountOnExit
            >
              <ContentWrapper className={animationDirection}>
                <Content>
                  <h2>{section.title}</h2>

                  <Items>
                    {section.items.map(
                      ({ title: itemTitle, icon: Icon, description }) => (
                        <Item key={itemTitle}>
                          <IconWrapper>
                            <Icon />
                          </IconWrapper>

                          <ItemInfo>
                            <ItemTitle>{itemTitle}</ItemTitle>

                            <p>{description}</p>
                          </ItemInfo>
                        </Item>
                      ),
                    )}
                  </Items>
                </Content>

                <ImageWrapper>
                  <Image data-show={section.key === 'contacts' || undefined}>
                    <StaticImage
                      alt='Contacts demo'
                      src='./assets/feature-contacts.png'
                      placeholder='none'
                      loading='eager'
                      layout='fullWidth'
                    />
                  </Image>
                  <Image
                    data-show={section.key === 'communication' || undefined}
                  >
                    <StaticImage
                      alt='Communication demo'
                      src='./assets/feature-communication.png'
                      placeholder='none'
                      loading='eager'
                      layout='fullWidth'
                    />
                  </Image>
                  <Image data-show={section.key === 'calendar' || undefined}>
                    <StaticImage
                      alt='Calendar demo'
                      src='./assets/feature-calendar.png'
                      placeholder='none'
                      loading='eager'
                      layout='fullWidth'
                    />
                  </Image>
                  <Image data-show={section.key === 'tasks' || undefined}>
                    <StaticImage
                      alt='Tasks demo'
                      src='./assets/feature-tasks.png'
                      placeholder='none'
                      loading='eager'
                      layout='fullWidth'
                    />
                  </Image>
                  <Image data-show={section.key === 'files' || undefined}>
                    <StaticImage
                      alt='Files demo'
                      src='./assets/feature-files.png'
                      placeholder='none'
                      loading='eager'
                      layout='fullWidth'
                    />
                  </Image>
                </ImageWrapper>
              </ContentWrapper>
            </CSSTransition>
          </TransitionGroup>
        )}
      </SectionWrapper>
    </Wrapper>
  );
};

export default Features;
