import React from 'react';

import { Wrapper, Content, Tab } from './styled';

interface TabProps {
  id: string;
  name: string;
}

interface TabsProps {
  tabs: TabProps[];
  activeTabId: string;
  onChange: (tabId: string) => void;
}

const Tabs: React.FC<TabsProps> = ({ tabs, activeTabId, onChange }) => {
  const contentRef = React.useRef<HTMLDivElement>(null);

  const [leftVisible, setLeftVisible] = React.useState(true);
  const [rightVisible, setRightVisible] = React.useState(true);

  React.useEffect(() => {
    const target = contentRef.current;

    const onScroll = () => {
      if (target) {
        if (target.scrollWidth <= target.scrollLeft + target.clientWidth) {
          setRightVisible(true);
        } else {
          setRightVisible(false);
        }

        if (target.scrollLeft <= 0) {
          setLeftVisible(true);
        } else {
          setLeftVisible(false);
        }
      }
    };

    onScroll();

    if (target) {
      target.addEventListener('scroll', onScroll, {
        passive: true,
      });
    }

    return () => {
      if (target) {
        target.removeEventListener('scroll', onScroll);
      }
    };
  }, []);

  return (
    <Wrapper
      data-left-hidden={!leftVisible || undefined}
      data-right-hidden={!rightVisible || undefined}
    >
      <Content ref={contentRef}>
        {tabs.map(({ id, name }) => (
          <Tab
            key={id}
            data-active={activeTabId === id || undefined}
            onClick={() => onChange(id)}
          >
            {name}
          </Tab>
        ))}
      </Content>
    </Wrapper>
  );
};

export default Tabs;
