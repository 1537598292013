import { styled } from '@linaria/react';

import { MEDIA } from '@singledesk/theme';

import { LogoOutlined } from 'theme/icons/logo';

export const Wrapper = styled.main`
  position: relative;

  overflow: hidden;
`;

export const PseudoLogo = styled(LogoOutlined)`
  position: absolute;
  z-index: -1;
  top: -100px;
  left: 50%;

  width: 320px;
  height: 320px;
  flex-shrink: 0;

  transform: translateX(-50%);

  @media (min-width: ${MEDIA.tablet}) {
    display: none;
  }
`;
