import * as React from 'react';

import { Helmet } from 'react-helmet';

import metaImage from 'images/metaImage.png';

import {
  Main,
  Requirements,
  Safety,
  Business,
  Comfort,
  Teamwork,
  Communication,
  Groups,
  Features,
  GetAccess,
} from './screens';
import { Wrapper, PseudoLogo } from './styled';

const Home = () => (
  <React.Fragment>
    <Helmet>
      <title>Single Desk – единое цифровое рабочее пространство</title>

      <meta
        name='description'
        content='Все нужные инструменты в одном месте: чат, календари, задачи, безопасное хранение файлов и корпоративные адресные книги. Российское ПО!'
      />
      <meta
        property='og:title'
        content='Single Desk – единое цифровое рабочее пространство'
      />
      <meta
        property='og:description'
        content='Все нужные инструменты в одном месте: чат, календари, задачи, безопасное хранение файлов и корпоративные адресные книги. Российское ПО!'
      />
      <meta property='og:image' content={metaImage} />
      <meta name='viewport' content='width=device-width, initial-scale=1' />
    </Helmet>

    <Wrapper>
      <Main />

      <Features />

      <Requirements />

      <Safety />

      <Business />

      <Comfort />

      <Teamwork />

      <Communication />

      <Groups />

      <GetAccess />

      <PseudoLogo />
    </Wrapper>
  </React.Fragment>
);

export default Home;
