import { styled } from '@linaria/react';

import { TRANSITION, COLOR, MEDIA } from '@singledesk/theme';
import { BODY } from '@singledesk/typography';

export const Wrapper = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 100%;

  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;

    transition: var(${TRANSITION.default});
  }

  &[data-left-hidden]:before {
    box-shadow: inset 20px 0 8px -8px var(${COLOR.bPrimary});
  }

  &[data-right-hidden]:before {
    box-shadow: inset -20px 0 8px -8px var(${COLOR.bPrimary});
  }

  &[data-left-hidden]&[data-right-hidden]:before {
    box-shadow: inset 20px 0 8px -8px var(${COLOR.bPrimary}),
      inset -20px 0 8px -8px var(${COLOR.bPrimary});
  }
`;

export const Content = styled.div`
  position: relative;
  z-index: 2;
  max-width: 100vw;

  display: flex;
  align-items: center;

  overflow: auto;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  scrollbar-width: none; /* Hide scrollbar for Firefox */

  /* Hide scrollbar for Chrome, Safari */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Tab = styled.button`
  padding-bottom: 4px;

  ${BODY.LARGE_SEMIBOLD};
  color: var(${COLOR.nSecondary300});
  cursor: pointer;
  transition: var(${TRANSITION.default});
  background: none;
  border: none;
  border-bottom: 4px solid transparent;
  white-space: nowrap;
  scroll-snap-align: center;

  &:hover,
  &:focus,
  &:active {
    color: var(${COLOR.nPrimary});
  }

  &:not(:last-of-type) {
    margin-right: 20px;

    @media (min-width: ${MEDIA.laptop}) {
      margin-right: 40px;
    }
  }

  &[data-active] {
    color: var(${COLOR.nPrimary});
    border-bottom: 4px solid var(${COLOR.nPrimary});
  }
`;
