import * as React from 'react';

import { StaticImage } from 'gatsby-plugin-image';

import { StoreButton } from '@singledesk/button';
import { GooglePlay, AppStore } from '@singledesk/icons';

import { Mouse } from 'theme/icons/other';
import { LogoSmall } from 'theme/icons/logo';

import GetAccessButton from 'components/GetAccessButton';

import {
  SectionWrapper,
  Wrapper,
  Links,
  Subtitle,
  Content,
  ImageWrapper,
  Image,
  LogoWrapper,
  Controls,
  MouseWrapper,
} from './styled';

const Main = () => (
  <SectionWrapper>
    <Wrapper>
      <Content>
        <LogoWrapper>
          <LogoSmall />
        </LogoWrapper>

        <h1>Single Desk</h1>

        <Subtitle>
          Сформируйте единое цифровое пространство вместо разобщенных сервисов
        </Subtitle>

        <Controls>
          <GetAccessButton />

          <Links>
            <a
              rel='noopener noreferrer'
              target='_blank'
              href='https://play.google.com/store/apps/details?id=ru.atomation.singledesk'
            >
              <StoreButton
                label='Доступно в'
                title='Google Play'
                icon={GooglePlay}
              />
            </a>

            <a
              rel='noopener noreferrer'
              target='_blank'
              href='https://apps.apple.com/ru/app/single-desk/id1601444408'
            >
              <StoreButton
                label='Загрузите в'
                title='App Store'
                icon={AppStore}
              />
            </a>
          </Links>
        </Controls>
      </Content>

      <ImageWrapper>
        <Image>
          <StaticImage
            alt='Desktop App'
            src='./assets/iMac.png'
            placeholder='none'
            layout='fullWidth'
          />
        </Image>
      </ImageWrapper>
    </Wrapper>

    <MouseWrapper href='#features-section'>
      <Mouse />
    </MouseWrapper>
  </SectionWrapper>
);

export default Main;
